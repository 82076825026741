<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { computed, onMounted, watch } from "vue";
import { useStoreModule } from "./composables/useStoreModule";
export default {
  name: "App",
  setup() {
    const { getters } = useStoreModule("user");
    const user = computed(() => getters.getUser);
    const identifyUser = () => {
      if (user.value && user.value.email && window.$chatwoot) {
        try {
          window.$chatwoot.setUser(user.value.id, {
            email: user.value.email,
            name: user.value.name,
          });
        } catch (error) {
          console.error(error);
        }
      }

      if (user.value && user.value.id && user.value.email && window.FS) {
        try {
          window.FS.identify(user.value.id, {
            displayName: user.value.name,
            email: user.value.email,
          });
        } catch (error) {
          console.error(error);
        }
      }
    };

    watch(user, () => {
      identifyUser();
    });

    onMounted(() => {
      window.addEventListener("chatwoot:ready", function () {
        identifyUser();
      });
    });
  },
};
</script>

<style lang="scss">
#app {
  min-height: 100%;
  position: relative;
}
</style>
