import {
  deleteProject,
  getProjectDetails,
  getProjectPreview,
  patchUpdateProject,
  postCreateProject,
  postPublishProject,
  postUpdateThumbnail,
} from "@/api/project";
import Project, { PROJECT_ACCESS } from "@/models/Project.model";
import { loadProjectData } from "./loaders";
import { types } from "./mutations";
import { generateEditorScreenshot } from "@/utils/Screenshot";

export default {
  initializeProject: async (
    { dispatch, commit, rootGetters },
    { projectId, preview } = { projectId: null, preview: false }
  ) => {
    try {
      if (projectId === "new") {
        const organisation = rootGetters["user/getActiveOrganisation"];
        const { data, status } = await postCreateProject({
          organisation: organisation.id,
        });
        if (status === 200) {
          const project = new Project(data.project);
          commit(types.SET_ACTIVE_PROJECT, project);
          dispatch("UI/setAccessFlags", PROJECT_ACCESS.OWNER, { root: true });
          await loadProjectData({ dispatch }, project);
          return project;
        }
      } else {
        const fetchFunction = preview ? getProjectPreview : getProjectDetails;
        const { data, status } = await fetchFunction(projectId);
        if (status === 200) {
          const project = new Project(data.project);
          const access = data.access;
          commit(types.SET_ACTIVE_PROJECT_ACCESS, access);
          dispatch("UI/setAccessFlags", access, { root: true });
          commit(types.SET_ACTIVE_PROJECT, project);
          await loadProjectData({ dispatch }, project);
          return project;
        } else {
          return false;
        }
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  sync: async ({ state }, { action, payload }) => {
    const projectId = state.activeProject?.id;
    try {
      await patchUpdateProject(projectId, {
        action,
        payload,
      });
    } catch (error) {
      console.error(error);
    }
  },

  syncProjectThumbnail: async ({ state }) => {
    try {
      const project = state.activeProject;
      const imageBlob = await generateEditorScreenshot({ quality: 0.2 });
      const formData = new FormData();
      formData.append("file", imageBlob);
      const { status } = await postUpdateThumbnail(project.id, formData);
      if (status === 200) {
        console.log(`Thumbnail updated successfully`);
      }
    } catch (error) {
      console.error(error);
    }
  },

  publishMap: async ({ state, dispatch }, { title, description }) => {
    try {
      // generate thumbnail
      await dispatch("editor/updateBoundingBox", null, { root: true });
      const project = state.activeProject;
      const imageBlob = await generateEditorScreenshot({ quality: 0.5 });
      const formData = new FormData();
      formData.append("file", imageBlob);
      formData.append("title", title);
      formData.append("description", description);
      const { status } = await postPublishProject(project.id, formData);
      if (status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  setProjects: async ({ commit }, projectList) => {
    const projects = projectList.reduce((result, _project) => {
      const project = new Project(_project);
      result[project.id] = project;
      return result;
    }, {});
    commit(types.SET_PROJECTS, projects);
  },

  deleteProject: async ({ commit }, projectId) => {
    try {
      commit(types.DELETE_PROJECT, projectId);
      await deleteProject(projectId);
    } catch (error) {
      console.error(error);
    }
  },

  clearProjects: async ({ commit }) => {
    commit(types.SET_PROJECTS, {});
  },

  reset: async ({ commit, dispatch }) => {
    dispatch("editor/reset", null, { root: true });
    dispatch("map/reset", null, { root: true });
    dispatch("UI/reset", null, { root: true });
    commit(types.RESET);
  },
};
