import { createRouter, createWebHistory } from "vue-router";
import routeInterceptor from "./routeInterceptor";
import { store } from "@/store";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/auth",
      name: "auth",
      meta: {
        redirectIfLoggedIn: true,
      },
      component: () => import("@/pages/AuthPage.vue"),
      children: [
        {
          path: "signup",
          name: "signup",
          component: () => import("@/components/auth/SignupForm.vue"),
        },
        {
          path: "login",
          name: "login",
          component: () => import("@/components/auth/LoginForm.vue"),
        },
        {
          path: "onboard",
          name: "Onboard",
          component: () => import("@/components/auth/OnboardForm.vue"),
        },
        {
          path: "check-email",
          name: "Check your email",
          component: () => import("@/components/auth/CheckEmail.vue"),
        },
        {
          path: "forgot-password",
          name: "Forgot Password",
          component: () => import("@/components/auth/ForgotPassword.vue"),
        },
        {
          path: "reset-password/:token",
          name: "Reset Password",
          component: () => import("@/components/auth/ResetPassword.vue"),
        },
        {
          path: "",
          redirect: "/auth/login",
        },
      ],
    },
    {
      path: "/logout",
      name: "logout",
    },
    {
      path: "/public/:id",
      component: () => import("@/pages/MapStudio.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/:orgId/map/:id",
      component: () => import("@/pages/MapStudio.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/:orgId",
      component: () => import("@/pages/HomePage.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "learn",
          component: () => import("@/components/home/LearnView.vue"),
        },
        {
          path: "maps",
          component: () => import("@/components/home/MapsList.vue"),
        },
        {
          path: "workspace/new",
          component: () => import("@/components/home/NewWorkspace.vue"),
        },
        {
          path: "workspace",
          component: () => import("@/components/home/WorkspaceSettings.vue"),
        },
        {
          path: "settings",
          component: () => import("@/pages/SettingsPage.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/pages/HomePage.vue")
    }
  ],
});

router.beforeEach(routeInterceptor);
// checks for PWA updates on route change
router.afterEach(() => {
  try {
    window.navigator.serviceWorker.getRegistrations().then((regs) =>
      regs.forEach((reg) => {
        reg.update();
      })
    );
  } catch (error) {
    console.log(`Unable to check for updates on route change`);
    console.error(error);
  }
});

const createRoute = (target = "") => {
  const activeOrg = store["getters"]["user/getActiveOrganisation"];
  if (activeOrg && activeOrg._id) {
    return `/${activeOrg._id}${target}`;
  } else {
    return `/maps`
  }
};

export { router, createRoute };
